<template>
  <div class="ownerDecision">
    <div class="section-warpper">
      <div class="section-title">{{ detail.title }}</div>
      <div class="section-text">{{ detail.content }}</div>
      <div class="section-picture">
        <vote-pic-list
          :picList="detail.imageUrls ? detail.imageUrls.split(',') : []"
        ></vote-pic-list>
      </div>
      <div class="section-date" v-if="detail.voteEndDate">
        投票期：{{ `${detail.voteStartDate}~${detail.voteEndDate}` }}
      </div>
      <div class="section-tip">
        注：社区发起的议题上会，需业委会半数人通过才可公示，业委会发起的议题上会，社区有一人同意即可公示
      </div>
      <div
        class="section-decision"
        v-if="detail.canChoose == 1 && !detail.isChosen"
      >
        <div class="section-decision--button oppose" @click="submit(2)">
          反对
        </div>
        <div class="section-decision--button agree" @click="submit(1)">
          支持
        </div>
      </div>
      <div class="section-decision-result" v-if="detail.isChosen">
        <div
          :class="`section-decision--button ${
            detail.isChosen == 2 ? 'oppose' : 'agree'
          }`"
        >
          {{ chosenMap[detail.isChosen] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getDetailURL, supportOrOpposeSubjectURL } from "./api";
import votePicList from "./components/picList.vue";

export default {
  name: "ownerDecision",
  components: {
    votePicList,
  },
  data() {
    return {
      voteId: "",
      chosenMap: {
        1: "已支持",
        2: "已反对",
      },
      detail: {
        title: "",
        content: "",
        imageUrls: "",
        voteStartDate: "",
        voteEndDate: "",
        canChoose: 0,
        isChosen: 0,
      },
      timer: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { id } = this.$route.query;
    this.voteId = id;
    this.getDetail();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    async getDetail() {
      let params = {
        id: this.voteId,
        userId: this.userId,
      };
      let res = await this.$axios.get(getDetailURL, { params: params });
      if (res.code === 200) {
        this.detail = res.data;
      }
    },
    submit(val) {
      this.$axios
        .post(supportOrOpposeSubjectURL, null, {
          params: {
            voteId: this.voteId,
            userId: this.userId,
            houseId: this.houseId || this.communityId || undefined,
            votechoice: val,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({
              message: "提交成功，3s后自动跳转投票厅",
              forbidClick: true,
              duration: 3000,
            });
            const _this = this;
            this.timer = setTimeout(() => {
              _this.$toast.clear();
              _this.$router.replace({
                name: "voteHall",
              });
            }, 2500);
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.ownerDecision {
  box-sizing: border-box;
  width: 100%;
  background: #f6f8fb;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 20px 30px;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-warpper {
    min-height: calc(100vh - 40px);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 36px 64px;
    border-radius: 16px;
  }
  .section-title {
    font-size: 32px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .section-text {
    font-size: 28px;
    font-weight: 400;
    color: #7b4244;
    line-height: 40px;
    margin-bottom: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .section-picture {
    margin-bottom: 30px;
  }
  .section-date,
  .section-tip {
    font-size: 24px;
    font-weight: 400;
    color: #c8c8c8;
    line-height: 34px;
  }
  .section-tip {
    flex: 1;
    margin-top: 20px;
  }
  .section-decision,
  .section-decision-result {
    display: flex;
    justify-content: space-between;
    .section-decision--button {
      width: 262px;
      height: 74px;
      border-radius: 39px;
      color: #ffffff;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.oppose {
        background: linear-gradient(270deg, #ffc9c4 0%, #ff9c9c 100%);
        border: 8px solid #fff0f0;
      }
      &.agree {
        background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border: 8px solid #ecf7ff;
      }
    }
  }
  .section-decision-result {
    display: flex;
    justify-content: center;
  }
}
</style>
