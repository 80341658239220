<template>
  <div class="addDiscussion">
    <div class="section-form">
      <div class="section-item">
        <div class="section-item--label">姓名</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.userName"
            disabled
            :maxlength="20"
            inputAlign="right"
            placeholder="请填写姓名"
          ></v-input>
        </div>
      </div>
      <div class="section-item radio">
        <div class="section-item--label">是否匿名发布</div>
        <div class="section-item--control">
          <v-radio-group
            :radioObj="cryptonymOps"
            :value.sync="form.isAnonymity"
          ></v-radio-group>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">议题标题</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.title"
            inputAlign="right"
            placeholder="请输入标题"
          ></v-input>
        </div>
      </div>
      <div class="section-item textarea">
        <div class="section-item--label">议题内容</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.content"
            placeholder="请输入议题内容"
            type="textarea"
            :autosize="{
              minHeight: 160,
              maxHeight: 160,
            }"
            :maxlength="500"
            :showWordLimit="true"
          ></v-input>
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">议题图片</div>
        <div class="section-item--control">
          <div class="upload">
            <v-upload
              :imgUrl.sync="form.imageUrls"
              :activityPicture.sync="form.imageUrls1"
              ref="upload"
              :maxCount="9"
            ></v-upload>
            <p>注：最多可上传9张图片</p>
          </div>
        </div>
      </div>

      <div class="surebtn" @click="submit">
        <div class="btn">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addInfoURL, userInfoURL } from "./api";

export default {
  name: "addDiscussion",
  data() {
    return {
      cryptonymOps: [
        { name: 1, value: "是" },
        { name: 0, value: "否" },
      ],
      userInfo: {},
      form: {
        userName: "",
        isAnonymity: 0,
        title: "",
        content: "",
        imageUrls: "",
        imageUrls1: "",
      },
      timer: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    // // ./index 页面存储
    // this.userInfo = sessionStorage.getItem("householdVote_userInfo");
    // this.form.userName = this.userInfo.userName;
    this.getuserInfo();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    // 获取用户信息
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(userInfoURL, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
        this.form.userName = this.userInfo.userName;
      }
    },
    submit() {
      if (!this.valid()) {
        return;
      }
      this.$axios
        .post(addInfoURL, {
          type: 1, // 1-议题，2-业主大会，3-议题上会
          ...this.form,
          imageUrls: this.form.imageUrls1,
          houseId: this.houseId || this.communityId || undefined,
          optUserId: this.userId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({
              message: "议事提交成功，待业委会审核，3s后自动跳转我的议题页面",
              forbidClick: true,
              duration: 3000,
            });
            const _this = this;
            this.timer = setTimeout(() => {
              _this.$toast.clear();
              _this.$router.replace({
                name: "myDiscussionList",
              });
            }, 2500);
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 验证必填项
    valid() {
      const { userName, title, content, imageUrls } = this.form;
      let message = undefined;
      if (userName === "") {
        message = "姓名不可为空";
      } else if (title === "") {
        message = "议题标题不可为空";
      } else if (content === "") {
        message = "议题内容不可为空";
      }
      //  else if (imageUrls === "") {
      //   message = "请上传议题图片";
      // }
      if (message) {
        this.$toast({
          message,
          duration: 1500,
        });
      }
      return !message;
    },
  },
};
</script>
<style lang="less" scoped>
.addDiscussion {
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  background: #f6f8fb;
  min-height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: calc(
    30px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    30px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-form {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 32px 32px 50px 32px;
    background: #ffffff;
    .section-item {
      display: flex;
      &:nth-child(-n + 3) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      }
      .section-item--label {
        flex: 1;
        line-height: 88px;
        color: #333333;
        font-size: 30px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
      }
      .section-item--control {
        max-width: 400px;
      }
      &.radio {
        .section-item--control {
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &.textarea {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          ::v-deep .van-cell {
            border-radius: 16px;
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      &.upload {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          // ::v-deep .van-cell {
          //   background: rgba(0, 0, 0, 0.05);
          // }
          ::v-deep .van-uploader {
            .van-uploader__preview {
              .van-uploader__preview-image {
                vertical-align: middle;
                width: 160px;
                height: 160px;
                img {
                  vertical-align: middle;
                  overflow: hidden;
                  margin: 0;
                  padding: 0;
                }
              }
              .van-uploader__preview-delete {
                width: 28px;
                height: 28px;
                border-radius: 0 0 0 24px;
                i {
                  position: absolute;
                  vertical-align: middle;
                  font-size: 32px;
                  top: 45%;
                  left: 55%;
                  transform: translateX(-50%) translateY(-50%) scale(0.5);
                }
              }
            }
            .van-uploader__upload {
              width: 160px;
              height: 160px;
            }
          }
          p {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
            margin-top: 20px;
            font-weight: 400;
          }
        }
      }
    }
    .surebtn {
      margin-top: 78px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 492px;
        height: 74px;
        background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border-radius: 39px;
        border: 8px solid #ecf7ff;
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
}
</style>
