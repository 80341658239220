<template>
  <div class="joinHall">
    <div class="section-warpper" v-if="detail.title">
      <div class="section-detail">
        <div class="section-title">{{ detail.title }}</div>
        <div class="section-text">
          {{ detail.votePublicityContent || detail.content }}
        </div>
        <div class="section-picture">
          <vote-pic-list
            :picList="detail.imageUrls ? detail.imageUrls.split(',') : []"
          ></vote-pic-list>
        </div>
        <div class="section-vote">
          <div
            class="option"
            :class="item.value == form.votechoice ? 'select' : ''"
            v-for="(item, index) in options"
            :key="index"
            @click="clickOption(item)"
          >
            <span>{{ item.text }}</span>
            <img src="./img/select_icon.png" alt="" />
          </div>
        </div>
      </div>

      <div class="section-item signcCanvas">
        <div class="section-item--label">
          <span>电子签名</span>
          <div class="reset" @click="handleReset">
            <span>重置</span>
          </div>
        </div>
        <div class="section-item--control">
          <div class="sign">
            <img v-if="!isShowsignCanvas" :src="form.esignature" alt="" />
            <signCanvas
              v-else
              ref="esign"
              :width="614"
              :height="242"
              :isCrop="isCrop"
              :lineWidth="lineWidth"
              :lineColor="lineColor"
              :bgColor.sync="bgColor"
            />
          </div>
        </div>
      </div>

      <div class="surebtn" @click="submitBefore">
        <div class="btn">提 交</div>
      </div>
    </div>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { base64toFile } from "@/utils/utils.js";
import { getDetailURL, voteSubmitURL, userInfoURL, uploadURL } from "./api";
import votePicList from "./components/picList.vue";
import signCanvas from "@/components/bussiness/signCanvas";

export default {
  name: "joinHall",
  components: {
    votePicList,
    signCanvas,
  },
  data() {
    return {
      voteId: "",
      detail: {
        id: "",
        title: "",
        content: "",
        votePublicityContent: "",
        esignature: "",
        imageUrls: "",
        votechoice: 0,
        signatureIsOpen: 0,
      },
      optionMap: {
        1: "支持",
        2: "反对",
        3: "弃权-随大多数票",
        4: "弃权-不随大多数票",
      },
      options: [
        // {
        //   value: "1",
        //   text: "支持",
        // },
        // {
        //   value: "2",
        //   text: "反对",
        // },
        // {
        //   value: "3",
        //   text: "弃权-随大多数票",
        // },
        // {
        //   value: "4",
        //   text: "弃权-不随大多数票",
        // },
      ],
      userInfo: {},
      timer: null,
      form: {
        votechoice: 0,
        esignature: "",
      },
      /** 电子签名 */
      isShowsignCanvas: true,
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    async initData() {
      const { id } = this.$route.query;
      this.voteId = id;
      await this.getuserInfo();
      await this.getDetail();
    },
    // 获取用户信息
    async getuserInfo() {
      let params = {
        id: this.voteId,
        userId: this.userId,
      };
      let res = await this.$axios.get(userInfoURL, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
        if (this.userInfo.houseOwnerAuditStatus == 1) {
          // 通过houseOwnerAuditStatus判断是否业主
          this.firstStatus = 2;
        }

        // this.form.esignature = this.userInfo.esignature;
        // // 签名展示图片还是签名组件
        // this.isShowsignCanvas = !!this.form.esignature;
        /** 电子签名、详情内自带 */
        this.form.esignature = this.userInfo.esignature;
        this.isShowsignCanvas = !this.form.esignature;
      }
    },
    async getDetail() {
      let params = {
        id: this.voteId,
        userId: this.userId,
      };
      let res = await this.$axios.get(getDetailURL, { params: params });
      if (res.code === 200) {
        this.detail = res.data;
        /* 当前用户的选项 */
        this.form.votechoice = this.detail.votechoice;
        /** 可选项 支持、反对、弃权-随大多数、弃权-不随大多数*/
        let options = [];
        this.detail.voteOptions &&
          this.detail.voteOptions.split(",").forEach((v) => {
            options.push({
              value: v,
              text: this.optionMap[v],
            });
          });
        this.options = options;

        // /** 电子签名、详情内自带 */
        // this.form.esignature = this.detail.esignature;
        // this.isShowsignCanvas = !this.form.esignature;
        // 签名展示图片还是签名组件
        // this.isShowsignCanvas = !!this.detail.esignature;
      }
    },
    clickOption(item) {
      this.form.votechoice = item.value;
    },
    // 清空签名
    handleReset() {
      this.isShowsignCanvas = true;
      this.form.esignature = "";
      this.$nextTick(() => {
        this.$refs.esign.reset();
      });
    },
    submitBefore() {
      if (!this.valid()) {
        return;
      }
      if (!this.isShowsignCanvas) {
        // 已存在签名图片,直接提交
        this.submit();
      } else {
        // 未存在签名图片
        this.handleGenerate();
      }
    },
    /* signCanvas start */
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          // alert(err); // 画布没有签字时会执行这里 'Not Signned'
          // if (err == "Not Signned") {
          //   this.$toast("未签名");
          // } else {
          this.$toast(err);
          // }
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadURL, formData);
      if (res.code == 200) {
        this.form.esignature = res.data.link;
        this.submit();
      }
    },
    submit() {
      this.$axios
        .post(voteSubmitURL, null, {
          params: {
            voteId: this.voteId,
            userId: this.userId,
            houseId: this.houseId || this.communityId || undefined,
            ...this.form,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({
              message: "投票成功，3s后自动跳转投票厅",
              forbidClick: true,
              duration: 3000,
            });
            const _this = this;
            this.timer = setTimeout(() => {
              _this.$toast.clear();
              _this.$router.replace({
                name: "voteHall",
              });
            }, 2500);
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 验证必填项
    valid() {
      const { esignature, votechoice } = this.form;
      let message = undefined;
      // if (esignature === "") {
      //   message = "电子签名不可为空";
      // } else
      if (votechoice === "") {
        message = "选项不可为空";
      }
      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },
  },
};
</script>

<style lang="less" scoped>
.joinHall {
  box-sizing: border-box;
  width: 100%;
  background: #f6f8fb;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 20px 30px;
  padding-bottom: calc(
    40px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    40px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-warpper {
    min-height: calc(100vh - 60px - constant(safe-area-inset-bottom));
    min-height: calc(100vh - 60px - env(safe-area-inset-bottom));
    background: #ffffff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 36px 90px;
    border-radius: 16px;
  }
  .section-detail {
    padding: 44px 32px 56px;
    background: #f8f8f8;
    border-radius: 8px;
    margin-bottom: 60px;
    .section-title {
      font-size: 32px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      margin-bottom: 30px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
    .section-text {
      font-size: 28px;
      font-weight: 400;
      color: #7b4244;
      line-height: 40px;
      margin-bottom: 30px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
    .section-picture {
      margin-bottom: 30px;
    }
    .section-vote {
      .option {
        // width: 520px;
        height: 80px;
        margin: 30px auto 0;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 8px;
        border: 2px solid #e2e2e2;
        color: #226297;
        font-size: 30px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: none;
        }
        &.select {
          background: #ecf5ff;
          border: 2px solid #0578fa;
          img {
            display: inline;
            width: 26px;
            height: 22px;
            margin-left: 12px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .section-item {
    display: flex;
    .section-item--label {
      flex: 1;
      line-height: 88px;
      color: #333333;
      font-size: 30px;
      text-align: left;
      font-weight: 400;
      white-space: nowrap;
    }
    .section-item--control {
      max-width: 400px;
    }
    &.signcCanvas {
      display: block;
      .section-item--label {
        display: flex;
        align-items: center;
        span {
          flex: 1;
        }
        .reset {
          font-size: 28px;
          color: #0578fa;
          letter-spacing: 4px;
        }
      }
      .section-item--control {
        box-sizing: border-box;
        max-width: none;
        .sign {
          margin-bottom: 12px;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 100%;
            height: 242px;
          }
        }
      }
    }
  }

  .surebtn {
    margin-top: 78px;
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 492px;
      height: 74px;
      background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
      border-radius: 39px;
      border: 8px solid #ecf7ff;
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      letter-spacing: 4px;
    }
  }
}
</style>
