<template>
  <div class="householdVote">
    <div class="section-box">
      <div class="section-tabs">
        <div
          class="section-tab"
          v-for="(item, index) in tabs"
          :key="index"
          @click="toRouter(item)"
        >
          <div class="section-tab-bg">
            <img :src="require(`${item.imgUrl}`)" alt="" />
          </div>
          <div class="section-tab-txt">
            <div class="section-tab-title">
              <span>{{ item.title }}</span>
              <img src="./img/arrow_right.png" alt="" />
            </div>
            <div class="section-tab-subTitle">{{ item.subTitle }}</div>
          </div>
        </div>
      </div>
      <div class="section-tip">
        <img src="./img/notice.png" alt="" />
        <span>
          若业主发起议事，支持票超过社区常住业主人数的20%，可线上发起开业主大会，进行投票选举。
        </span>
      </div>
      <div class="section-new-discussion">
        <div class="discussion-title"><span>最近议题</span></div>
        <div class="discussion-content">
          <div
            class="discussion-content-item"
            v-for="(item, index) in discussionList"
            :key="index"
            @click="toDiscussionDetail(item)"
          >
            <div class="content-item--text">{{ item.title }}</div>
            <div class="content-item--date">{{ item.insertTime }}</div>
            <div class="content-item--detail">{{ item.content }}</div>
            <div class="content-item--operate" @click.stop>
              <div class="operate-user">
                <div class="operate-user--picture">
                  <img
                    :src="
                      item.isAnonymity == 1 || !item.avatar
                        ? require('./img/default_avatar.png')
                        : item.avatar
                    "
                    alt=""
                  />
                </div>
                <div class="operate-user--name">
                  {{ item.userName }}
                </div>
              </div>
              <div class="operate-icon">
                <div
                  :class="['icon-wrapper', item.isChosen == 1 ? 'blue' : '']"
                  @click="clickOperate(item, 'like')"
                >
                  <img
                    v-show="item.isChosen != 1"
                    src="./img/like_gray.png"
                    alt=""
                  />
                  <img
                    v-show="item.isChosen == 1"
                    src="./img/like_blue.png"
                    alt=""
                  />
                  <span>{{ item.publicSupport }}</span>
                </div>

                <div
                  :class="['icon-wrapper', item.isChosen == 2 ? 'red' : '']"
                  @click="clickOperate(item, 'step')"
                >
                  <img
                    v-show="item.isChosen != 2"
                    src="./img/step_gray.png"
                    alt=""
                  />
                  <img
                    v-show="item.isChosen == 2"
                    src="./img/step_red.png"
                    alt=""
                  />
                  <span>{{ item.publicOppose }}</span>
                </div>

                <div class="icon-wrapper" @click="clickOperate(item, 'topic')">
                  <img class="topicImg" src="./img/topic_gray.png" alt="" />
                  <span>{{ item.propositionPersons }}</span>
                </div>
              </div>
            </div>
          </div>
          <v-empty
            v-show="discussionList && discussionList.length == 0"
            description="暂无数据"
          />
        </div>
      </div>
      <div class="section-new-vote">
        <div class="vote-title"><span>最近投票</span></div>
        <div class="vote-content">
          <div
            class="vote-content-item"
            v-for="(item, index) in voteList"
            :key="index"
            @click="toVoteDetail(item)"
          >
            <div class="content-item-text">{{ item.title }}</div>
            <div class="content-item-date">
              <span>投票期：</span>
              <span>{{ `${item.voteStartDate}~${item.voteEndDate}` }}</span>
            </div>
            <div class="content-item-wrapper">
              <div class="content-item-detail">
                <span>投票内容：</span>
                <span>{{ item.votePublicityContent || item.content }}</span>
              </div>
              <div class="content-item-button" :style="item.status | btnCss">
                {{ item.status | btnTxt }}
              </div>
            </div>
          </div>
          <v-empty
            v-show="voteList && voteList.length == 0"
            description="暂无数据"
          />
        </div>
      </div>
    </div>
    <vote-dialog
      :show="show"
      :title="dialogInfo.title"
      :content="dialogInfo.content || ''"
      @check="checkSubmit"
    />
    <vote-tip
      :show="showTip"
      :content="contentTip"
      :confirmTxt="textTip"
      @check="checkRouter"
      @close="closeTip"
    ></vote-tip>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { toRegister } from "@/utils/common";
import {
  getLastVoteListURL,
  // getPropositionListURL,
  // getVoteURL,
  // cancelPraiseURL,
  // addPraiseURL,
  userInfoURL,
  getVoteLawURL,
  checkSubmitURL,
} from "./api";
import { voteStatusMap, setVoteStatusOps } from "./map";
import voteDialog from "./components/vote-dialog.vue";
import voteTip from "./components/vote-tip.vue";

export default {
  components: {
    voteDialog,
    voteTip,
  },
  data() {
    return {
      // once: true, // 是否第一次进入该页面
      tabs: [
        {
          name: "discussionHall",
          title: "议事厅",
          subTitle: "社区大小事，一事一议",
          imgUrl: "./img/discussion_bg.png",
        },
        {
          name: "voteHall",
          title: "投票厅",
          subTitle: "社区建设，业主大会",
          imgUrl: "./img/vote_bg.png",
        },
      ],
      discussionList: [
        // {
        //   id: "1",
        //   text: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   detail:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   date: "2023-2-28 10:30:47",
        //   picture: "",
        //   name: "刘月",
        //   isCryptonym: false, // 是否匿名
        //   isLike: false,
        //   likeNum: 41,
        //   isStep: false,
        //   stepNum: 1,
        //   topicNum: 42,
        // },
        // {
        //   id: "2",
        //   text: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   detail:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   date: "2023-2-28 10:30:47",
        //   picture: "",
        //   name: "刘月",
        //   isCryptonym: true, // 是否匿名
        //   isLike: true,
        //   likeNum: 41,
        //   isStep: true,
        //   stepNum: 1,
        //   topicNum: 42,
        // },
      ],
      voteList: [
        // {
        //   title: "小区第一次业主大会 物业续聘投票表决",
        //   voteStartDate: "2023-02-23 12:00:00",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   content:
        //     "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaadfasdfsadfasfsadfasdfasdf中感到公安十多个那是肯定放哪了说的",
        //   status: 6,
        // },
        // {
        //   title: "小区第一次业主大会 物业续聘投票表决",
        //   voteStartDate: "2023-02-23 12:00:00",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   content: "例如是否续聘",
        //   status: 7,
        // },
        // {
        //   title: "小区第一次业主大会 物业续聘投票表决",
        //   voteStartDate: "2023-02-23 12:00:00",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   content:
        //     "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaadfasdfsadfasfsadfasdfasdf中感到公安十多个那是肯定放哪了说的",
        //   status: 8, // 1 公示中 2 投票中 3 唱票复核中 4 公示投票结果 5 审核失败
        // },
      ],
      userInfo: {
        // 用户信息
        // realNameAuditStatus: '', // 实名审核状态 -1:未提交过申请 0:待审核 1:审核通过 2:审核驳回
        // houseOwnerAuditStatus: 1, // 产权人审核状态 -1:未提交过申请 0:待审核 1:审核通过 2:审核驳回
        // userName: '', // 真实姓名
      },
      /* vote-dialog */
      show: false, // 业主投票法律法规弹框是否弹出
      dialogInfo: {
        // 弹窗信息contentTip
        id: "",
        title: "",
        content: "",
        insertUser: "",
        insertTime: "",
        updateUser: "",
        updateTime: "",
        isRemind: undefined,
      },
      /* vote-tip */
      showTip: false,
      contentTip: "您还未进行实名身份认证，请先认证身份才可进行",
      textTip: "去认证",
    };
  },
  filters: {
    btnTxt(status) {
      if (status == 1 || status == 4) {
        // 审核通过、待投票 都显示为公示中
        return "公示中";
      }
      return voteStatusMap[status];
    },
    btnCss(status) {
      let voteStatusCSS = {
        0: {
          color: "rgba(169, 169, 169, 0.85)",
          background: "#EAEAEA",
        },
        1: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        2: {
          color: "#FFFFFF",
          background: "#D93747",
        },
        3: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        4: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        5: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        6: {
          color: "#FFFFFF",
          background: "#9AA9C3",
        },
        7: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        8: {
          color: "#FFFFFF",
          background: "#9AA9C3",
        },
      };
      return voteStatusCSS[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    if (await toRegister(this.$route.path, this.$route.query, "业主投票")) {
      sessionStorage.setItem("cancelToastLoading", "cancelToastLoading");
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      await this.getuserInfo();
      await Promise.all([this.getALLList(), this.getDialogInfo()]);
      this.$toast.clear();
      sessionStorage.removeItem("cancelToastLoading");
    }
  },
  methods: {
    toRouter(item) {
      /* 权限 */
      // 不是实名的情况
      if (!this.realValid()) {
        return;
      }
      // 不是业主的情况
      if (!this.houseHoldValid()) {
        return;
      }
      /** end */
      this.$router.push({
        name: item.name,
      });
    },
    // 点击标题跳转议题详情页
    toDiscussionDetail(item) {
      /* 权限 */
      // 不是实名的情况
      if (!this.realValid()) {
        return;
      }
      // 不是业主的情况
      if (!this.houseHoldValid()) {
        return;
      }
      /** end */
      let params = {
        id: item.id,
      };
      // if (window.location.origin == "xxx") {
      // this.$router.push({
      //   name: "partnerStyleTitle",
      //   query: {
      //     id: item.id,
      //   },
      // });
      // } else {
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/dicussionDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
      // }
    },
    // 点击标题跳转投票详情页
    toVoteDetail(item) {
      /* 权限 */
      // 不是实名的情况
      if (!this.realValid()) {
        return;
      }
      // 不是业主的情况
      if (!this.houseHoldValid()) {
        return;
      }
      // 如果状态为待审核，则不可查看详情
      if (item.status == 0) {
        return;
      }
      /** end */
      this.$router.push({
        name: "voteDetail",
        query: {
          id: item.id,
        },
      });
      // let params = {
      //   id: item.id,
      // };
      // if (wx.miniProgram) {
      //   wx.miniProgram.navigateTo({
      //     url: `/xubPages/dicussionVoteDetail/index?params=${JSON.stringify(
      //       params
      //     )}`,
      //   });
      // }
    },
    // 点击提示框跳转实名认证页面
    checkRouter() {
      if (
        this.userInfo.realNameAuditStatus === "0" ||
        this.userInfo.realNameAuditStatus === 0
      ) {
        this.showTip = false;
        return;
      }
      let params = {
        // id: item.id,
        formPath: "/futureTreat/householdVote",
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/realNameAuthentication/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    // 点击提示框关闭按钮
    closeTip() {
      this.showTip = false;
    },

    /* 接口 */
    // 获取弹框信息
    async getDialogInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(getVoteLawURL, { params: params });
      if (res.code === 200) {
        this.dialogInfo = res.data;
        // isRemind 是否提醒 0 不显示 1 显示
        this.show = this.dialogInfo.isRemind == 1;
      }
    },
    // 获取用户信息
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(userInfoURL, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
        // 模块搬迁频繁 避免使用vuex
        sessionStorage.setItem("householdVote_userInfo", this.userInfo);
      }
    },
    // 获取最近的议题/投票列表
    async getALLList() {
      const res = await this.$axios.get(getLastVoteListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
        },
      });
      if (res.code === 200 && res.data) {
        this.discussionList = res.data.propositionList || [];
        this.voteList = res.data.voteList || [];
      }
    },
    // 加载列表数据
    // async getDiscussionList() {
    //   const res = await this.$axios.get(getPropositionListURL, {
    //     params: {
    //       tenantId: this.tenantId,
    //       houseId: this.houseId || this.communityId || undefined,
    //       userId: this.userId,
    //       status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
    //       topicType: 31, // 31 红色引领
    //       curPage: this.curPage,
    //       pageSize: this.pageSize,
    //       sortField: "NEW", // 排序方式， 最热 or 最新
    //     },
    //   });
    //   if (res.code === 200 && res.data && res.data.records) {
    //     this.discussionList = res.data.records.slice(0, 2);
    //   }
    // },
    // async onVoteList() {
    //   const res = await this.$axios.get(getVoteURL, {
    //     params: {
    //       tenantId: this.tenantId,
    //       houseId: this.houseId || this.communityId || undefined,
    //       userId: this.userId,
    //       status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
    //       topicType: 31, // 31 红色引领
    //       curPage: this.curPage,
    //       pageSize: this.pageSize,
    //       sortField: "NEW", // 排序方式， 最热 or 最新
    //     },
    //   });
    //   if (res.code === 200 && res.data && res.data.records) {
    //     this.voteList = res.data.records.slice(0, 3);
    //   }
    // },

    // 点赞、点踩、或点击评论
    clickOperate(item, str) {
      return;
      // let hasStr = ""; // 需要修改的判断字段
      // let countStr = ""; // 需要修改的总数字段
      // if (str === "topic") {
      //   // 评论
      //   this.$toast({
      //     message: "请进入详情页评论！",
      //     duration: 1500,
      //   });
      //   return;
      // } else if (str === "step") {
      //   // 点踩
      //   hasStr = "isStep";
      //   countStr = "stepNum";
      // } else if (str === "like") {
      //   // 点赞
      //   hasStr = "isLike";
      //   countStr = "likeNum";
      // }
      // /* 权限 */
      // // 不是实名的情况
      // if (!this.realValid()) {
      //   return;
      // }
      // // 不是业主的情况
      // if (!this.houseHoldValid()) {
      //   return;
      // }
      // /** end */
      // this.$axios
      //   .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
      //     params: {
      //       targetId: item.id,
      //       userId: this.userId,
      //       tenantId: this.tenantId || undefined,
      //       communityId: this.communityId || undefined,
      //       roomId: this.houseId || undefined,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.code === 200) {
      //       if (item[hasStr]) {
      //         // 取消
      //         item[hasStr] = false;
      //         item[countStr] -= 1;
      //       } else {
      //         // 点击
      //         item[hasStr] = true;
      //         item[countStr] += 1;
      //       }
      //       return;
      //     }
      //     this.$toast(res.msg || "操作失败，请稍后重试！");
      //   })
      //   .catch((res) => {
      //     this.$toast(res.msg || "操作失败，请稍后重试！");
      //   });
    },
    // 弹框确认按钮
    checkSubmit(val) {
      /* 权限 */
      // 不是实名的情况
      if (!this.realValid()) {
        return;
      }
      // 不是业主的情况
      if (!this.houseHoldValid()) {
        return;
      }
      /** end */
      // val 布尔值 是否已阅读
      let params = {
        lawId: this.dialogInfo.id,
        userId: this.userId,
        isRemind: val,
      };
      this.$axios
        .post(checkSubmitURL, null, { params })
        .then((res) => {
          if (res.code === 200) {
            this.show = false;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    /* 辅助方法 */
    // 实名认证
    realValid() {
      const { realNameAuditStatus } = this.userInfo;
      const result = realNameAuditStatus == 1;
      // 实名认证非审核通过状态时
      // if (realNameAuditStatus === 0 || realNameAuditStatus === "0") {
      //   this.$toast({
      //     message: "您的实名认证正在审核中，不可进行操作",
      //     icon: require("./img/audio_toast.png"),
      //     className: "toast-default-width_data-v-62decf8a",
      //     duration: 2000,
      //   });
      // } else if (realNameAuditStatus != 1) {
      //   this.showTip = true;
      // }
      if (realNameAuditStatus === 0 || realNameAuditStatus === "0") {
        this.contentTip = "您的实名认证审核中，通过实名认证后才可进行";
        this.textTip = "关 闭";
      }
      if (!result) {
        this.showTip = true;
      }
      return result;
    },
    // 业主认证
    houseHoldValid() {
      const { houseOwnerAuditStatus } = this.userInfo;
      // 实名认证非审核通过状态时
      if (houseOwnerAuditStatus === 0 || houseOwnerAuditStatus === "0") {
        this.$toast({
          message: "您的小区业主身份正在审核中，不可进行操作",
          icon: require("./img/audio_toast.png"),
          className: "toast-default-width_data-v-62decf8a",
          duration: 2000,
        });
      } else if (houseOwnerAuditStatus != 1) {
        this.$toast({
          message: "您不符合小区业主身份，不可进行操作",
          className: "toast-default-width_data-v-62decf8a",
          icon: require("./img/fail_toast.png"),
          duration: 2000,
        });
      }
      return houseOwnerAuditStatus == 1;
    },
    // 权限验证
    // permissionValid(type) {
    //   // type 1 仅判断实名认证 2 判断实名认证+业主认证
    //   if (type === 1) {
    //     // const { realNameAuditStatus } = this.userInfo;
    //     const result = this.userInfo.realNameAuditStatus == 1;
    //     // 实名认证非审核通过状态时
    //     if (!result) {
    //       this.showTip = true;
    //     }
    //     return result;
    //   } else if (type === 2) {
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.householdVote {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    padding: 36px 30px;
    .section-tabs {
      display: flex;
      justify-content: space-between;
      .section-tab {
        position: relative;
        width: 334px;
        max-width: 334px;
        height: 182px;
        .section-tab-bg {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        }
        .section-tab-txt {
          position: absolute;
          top: 28px;
          left: 16px;
          .section-tab-title {
            line-height: 40px;
            font-size: 28px;
            font-weight: bold;
            color: #002244;
            display: flex;
            align-items: center;
            img {
              width: 10px;
              height: 18px;
              vertical-align: middle;
              overflow: hidden;
              margin-left: 12px;
            }
          }
          .section-tab-subTitle {
            color: #8eaac6;
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }
    .section-tip {
      background: #ffffff;
      padding: 20px 24px;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 48px;
      font-size: 26px;
      margin-top: 30px;
      img {
        width: 32px;
        height: 40px;
        vertical-align: middle;
        overflow: hidden;
      }
    }
    .section-new-discussion {
      margin-top: 30px;
      .discussion-title {
        width: 100%;
        height: 92px;
        background: url("./img/new_discussion_bg.png") no-repeat top left;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        span {
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            background: #0654f6;
            margin-right: 20px;
          }
          position: relative;
          display: flex;
          align-items: center;
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
        }
      }
      .discussion-content {
        padding: 34px 24px 0;
        background: #ffffff;
        border-radius: 0 0 16px 16px;
        .discussion-content-item {
          &:not(:first-child) {
            margin-top: 44px;
          }
          &:not(:last-child) {
            border-bottom: 2px solid #f3f3f3;
          }
          .content-item--text {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            line-height: 40px;
            &:extend(.dhsl);
          }
          .content-item--date {
            font-size: 24px;
            color: #c8c8c8;
            line-height: 34px;
            height: 34px;
            margin: 14px 0;
          }
          .content-item--detail {
            &:extend(.dhsl);
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 36px;
          }
          .content-item--operate {
            padding: 34px 0 40px;
            display: flex;
            align-items: center;
            .operate-user {
              display: flex;
              align-items: center;
              .operate-user--picture {
                width: 58px;
                height: 58px;
                border-radius: 50%;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  // vertical-align: middle;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  object-fit: cover;
                }
              }
              .operate-user--name {
                font-size: 24px;
                color: #333333;
                line-height: 34px;
                max-width: 150px;
                &:extend(.dhsl);
                margin-left: 24px;
              }
            }
            .operate-icon {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              .icon-wrapper {
                &:not(:first-child) {
                  padding-left: 32px;
                }
                color: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                img {
                  width: 24px;
                  height: 24px;
                  vertical-align: middle;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  &.topicImg {
                    width: 32px;
                    height: 32px;
                  }
                }
                span {
                  padding-left: 12px;
                  font-size: 24px;
                  display: inline-block;
                  // min-width: 48px;
                  max-width: 72px;
                  white-space: normal;
                  word-wrap: break-word;
                  word-break: break-all;
                }
                &.blue {
                  color: #4ca4ff;
                }
                &.red {
                  color: #d93747;
                }
              }
            }
          }
        }
      }
    }
    .section-new-vote {
      margin-top: 30px;
      .vote-title {
        width: 100%;
        height: 92px;
        background: url("./img/new_vote_bg.png") no-repeat top left;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        span {
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            background: #ef6c4f;
            margin-right: 20px;
          }
          position: relative;
          display: flex;
          align-items: center;
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
        }
      }
      .vote-content {
        padding: 34px 24px 0;
        background: #ffffff;
        border-radius: 0 0 16px 16px;
        .vote-content-item {
          &:not(:first-child) {
            margin-top: 44px;
          }
          &:not(:last-child) {
            border-bottom: 2px solid #f3f3f3;
          }
          .content-item-text {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            line-height: 40px;
            &:extend(.dhsl);
          }
          .content-item-date {
            font-size: 24px;
            color: #c8c8c8;
            line-height: 34px;
            height: 34px;
            margin: 14px 0;
          }
          .content-item-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 34px;
            .content-item-detail {
              flex: 1;
              font-size: 26px;
              color: #7b4244;
              line-height: 36px;
              &:extend(.dhsl-1);
            }
            .content-item-button {
              margin: 0 10px;
              border-radius: 8px;
              white-space: nowrap;
              font-size: 24px;
              line-height: 34px;
              padding: 6px 26px;
            }
          }
        }
      }
    }
  }
}
</style>

<style>
/** 全局生效 */
.toast-default-width_data-v-62decf8a {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 300px;
  font-size: 28px;
  color: #ffffff;
  line-height: 40px;
  padding: 44px 40px;
}
</style>
