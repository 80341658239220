<template>
  <div class="householdVote">
    <div class="section-box">
      <div class="section-tabs">
        <div
          class="section-tab"
          v-for="(item, index) in tabs"
          :key="index"
          @click="toRouter(item)"
        >
          <div class="section-tab-bg">
            <img :src="require(`${item.imgUrl}`)" alt="" />
          </div>
          <div class="section-tab-txt">
            <div class="section-tab-title">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section-new-discussion">
        <div class="discussion-title"><span>议题列表</span></div>
        <div class="discussion-content">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="discussion-content-item"
              v-for="(item, index) in listData"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="content-item--text">{{ item.title }}</div>
              <div class="content-item--date">{{ item.insertTime }}</div>
              <div class="content-item--detail">{{ item.content }}</div>
              <div class="content-item--operate" @click.stop>
                <div class="operate-user">
                  <div class="operate-user--picture">
                    <img
                      :src="
                        item.isAnonymity == 1 || !item.avatar
                          ? require('./img/default_avatar.png')
                          : item.avatar
                      "
                      alt=""
                    />
                  </div>
                  <div class="operate-user--name">
                    {{ item.userName }}
                  </div>
                </div>
                <div class="operate-icon">
                  <!-- <div
                    class="icon-wrapper"
                    @click="clickOperate(item, 'topic')"
                  >
                    <img class="topicImg" src="./img/read_gray.png" alt="" />
                    <span>{{ item.readNum }}</span>
                  </div> -->
                  <div
                    :class="['icon-wrapper', item.isChosen == 1 ? 'blue' : '']"
                    @click="clickOperate(item, 'like')"
                  >
                    <img
                      v-show="item.isChosen != 1"
                      src="./img/like_gray.png"
                      alt=""
                    />
                    <img
                      v-show="item.isChosen == 1"
                      src="./img/like_blue.png"
                      alt=""
                    />
                    <span>{{ item.publicSupport }}</span>
                  </div>

                  <div
                    :class="['icon-wrapper', item.isChosen == 2 ? 'red' : '']"
                    @click="clickOperate(item, 'step')"
                  >
                    <img
                      v-show="item.isChosen != 2"
                      src="./img/step_gray.png"
                      alt=""
                    />
                    <img
                      v-show="item.isChosen == 2"
                      src="./img/step_red.png"
                      alt=""
                    />
                    <span>{{ item.publicOppose }}</span>
                  </div>

                  <div
                    class="icon-wrapper"
                    @click="clickOperate(item, 'topic')"
                  >
                    <img class="topicImg" src="./img/topic_gray.png" alt="" />
                    <span>{{ item.propositionPersons }}</span>
                  </div>
                </div>
              </div>
            </div>
          </v-list>
          <!-- <v-empty
            v-show="discussionList && discussionList.length == 0"
            description="暂无数据"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { cancelPraiseURL, addPraiseURL, getPropositionListURL } from "./api";

export default {
  name: "discussionHall",
  data() {
    return {
      tabs: [
        {
          name: "addDiscussion",
          title: "发起议题",
          imgUrl: "./img/add_discussion_bg.png",
        },
        {
          name: "myDiscussionList",
          title: "我的议题",
          imgUrl: "./img/my_discussion_bg.png",
        },
      ],
      /* v-list 通用 */
      // isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   picture: "",
        //   userName: "刘月",
        //   isAnonymity: 0, // 是否匿名
        //   isChosen: 0, // 0未选择 1支持 2反对
        //   // isLike: false,
        //   readNum: 109,
        //   // isStep: false,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        // },
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   picture: "",
        //   userName: "刘月",
        //   isAnonymity: 1, // 是否匿名
        //   isChosen: 1, // 0未选择 1支持 2反对
        //   // isLike: true,
        //   readNum: 109,
        //   // isStep: true,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        // },
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   picture: "",
        //   userName: "刘月",
        //   isAnonymity: 1, // 是否匿名
        //   isChosen: 2, // 0未选择 1支持 2反对
        //   // isLike: true,
        //   readNum: 109,
        //   // isStep: true,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        // },
      ], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      /** end */
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    toRouter(item) {
      this.$router.push({
        name: item.name,
      });
    },
    // 点击标题跳转议题详情页
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/dicussionDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },

    /* 接口 */
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getPropositionListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 点赞、点踩、或点击评论
    clickOperate(item, str) {
      return;
      // let hasStr = ""; // 需要修改的判断字段
      // let countStr = ""; // 需要修改的总数字段
      // if (str === "topic") {
      //   // 评论
      //   // this.$toast({
      //   //   message: "请进入详情页评论！",
      //   //   duration: 1500,
      //   // });
      //   return;
      // } else if (str === "step") {
      //   // 点踩
      //   // hasStr = "isStep";
      //   countStr = "publicOppose";
      // } else if (str === "like") {
      //   // 点赞
      //   // hasStr = "isLike";
      //   countStr = "publicSupport";
      // }
      // this.$axios
      //   .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
      //     params: {
      //       targetId: item.id,
      //       userId: this.userId,
      //       tenantId: this.tenantId || undefined,
      //       communityId: this.communityId || undefined,
      //       roomId: this.houseId || undefined,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.code === 200) {
      //       if (item[hasStr]) {
      //         // 取消
      //         item[hasStr] = false;
      //         item[countStr] -= 1;
      //       } else {
      //         // 点击
      //         item[hasStr] = true;
      //         item[countStr] += 1;
      //       }
      //       return;
      //     }
      //     this.$toast(res.msg || "操作失败，请稍后重试！");
      //   })
      //   .catch((res) => {
      //     this.$toast(res.msg || "操作失败，请稍后重试！");
      //   });
    },
    /* 辅助方法 */
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.householdVote {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    padding: 36px 30px;
    .section-tabs {
      display: flex;
      justify-content: space-between;
      .section-tab {
        position: relative;
        width: 334px;
        max-width: 334px;
        height: 182px;
        &:last-child {
          .section-tab-txt {
            .section-tab-title {
              color: #7b4244 !important;
            }
          }
        }
        .section-tab-bg {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        }
        .section-tab-txt {
          position: absolute;
          top: 66px;
          left: 30px;
          .section-tab-title {
            line-height: 40px;
            font-size: 36px;
            font-weight: bold;
            color: #002244;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .section-tip {
      background: #ffffff;
      padding: 20px 24px;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 48px;
      font-size: 26px;
      margin-top: 30px;
      img {
        width: 32px;
        height: 40px;
        vertical-align: middle;
        overflow: hidden;
      }
    }
    .section-new-discussion {
      margin-top: 30px;
      .discussion-title {
        width: 100%;
        height: 92px;
        background: url("./img/new_discussion_bg.png") no-repeat top left;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        span {
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            background: #0654f6;
            margin-right: 20px;
          }
          position: relative;
          display: flex;
          align-items: center;
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
        }
      }
      .discussion-content {
        padding: 34px 24px 0;
        background: #ffffff;
        border-radius: 0 0 16px 16px;
        .discussion-content-item {
          &:not(:first-child) {
            margin-top: 44px;
          }
          &:not(:last-child) {
            border-bottom: 2px solid #f3f3f3;
          }
          .content-item--text {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            line-height: 40px;
            &:extend(.dhsl);
          }
          .content-item--date {
            font-size: 24px;
            color: #c8c8c8;
            line-height: 34px;
            height: 34px;
            margin: 14px 0;
          }
          .content-item--detail {
            &:extend(.dhsl);
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 36px;
          }
          .content-item--operate {
            padding: 34px 0 40px;
            display: flex;
            align-items: center;
            .operate-user {
              display: flex;
              align-items: center;
              .operate-user--picture {
                width: 58px;
                height: 58px;
                border-radius: 50%;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  // vertical-align: middle;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  object-fit: cover;
                }
              }
              .operate-user--name {
                font-size: 24px;
                color: #333333;
                line-height: 34px;
                max-width: 150px;
                &:extend(.dhsl);
                margin-left: 24px;
              }
            }
            .operate-icon {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              .icon-wrapper {
                &:not(:first-child) {
                  padding-left: 24px;
                }
                color: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                img {
                  width: 24px;
                  height: 24px;
                  vertical-align: middle;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  &.topicImg {
                    width: 32px;
                    height: 32px;
                  }
                }
                span {
                  padding-left: 4px;
                  font-size: 24px;
                  display: inline-block;
                  // min-width: 48px;
                  max-width: 72px;
                  white-space: normal;
                  word-wrap: break-word;
                  word-break: break-all;
                }
                &.blue {
                  color: #4ca4ff;
                }
                &.red {
                  color: #d93747;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
