<template>
  <div class="voteHall">
    <div class="section-box">
      <div class="section-tabs">
        <div
          class="section-tab"
          v-for="(item, index) in tabs"
          :key="index"
          @click="toRouter(item)"
        >
          <div class="section-tab-bg">
            <img :src="require(`${item.imgUrl}`)" alt="" />
          </div>
          <div class="section-tab-txt">
            <div class="section-tab-title">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section-new-vote">
        <div class="vote-title"><span>投票列表</span></div>
        <div class="vote-content">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="vote-content-item"
              v-for="(item, index) in listData"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="content-item--text">
                <div class="tag" v-show="item.type == 3">
                  <img src="./img/discussion_sh_icon.png" alt="" />
                  <span>议题上会：</span>
                </div>
                {{ item.title }}
              </div>
              <div class="content-item-date">
                <span>投票期：</span>
                <span>{{ `${item.voteStartDate}~${item.voteEndDate}` }}</span>
              </div>
              <div class="content-item-wrapper">
                <div class="content-item-detail">
                  <span>投票内容：</span>
                  <span>{{ item.votePublicityContent || item.content }}</span>
                </div>
                <div
                  class="content-item-button"
                  v-show="item.type != 3"
                  :style="item.status | btnCss"
                >
                  {{ item.status | btnTxt }}
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import wx from "weixin-js-sdk";
import { voteListURL, checkIdentityURL } from "./api";
import { voteStatusMap, setVoteStatusOps } from "./map";

export default {
  name: "voteHall",
  data() {
    return {
      checkIdentity: 0, // 1是社区工作人员会业委会成员 0不是
      tabs: [
        {
          name: "issueMeeting",
          title: "议题上会",
          imgUrl: "./img/discussion_sh_bg.png",
        },
        {
          name: "ownersMeeting",
          title: "业主大会",
          imgUrl: "./img/proprietor_bg.png",
        },
      ],
      /* v-list 通用 */
      // isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [
        // {
        //   id: "1",
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   voteStartDate: "2023-2-28 10:30:47",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   type: 2,
        //   status: 4,
        // },
        // {
        //   id: "2",
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   voteStartDate: "2023-2-28 10:30:47",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   type: 3,
        //   status: 2,
        // },
      ], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      /** end */
    };
  },
  filters: {
    btnTxt(status) {
      if (status == 1 || status == 4) {
        // 审核通过、待投票 都显示为公示中
        return "公示中";
      }
      return voteStatusMap[status];
    },
    btnCss(status) {
      let voteStatusCSS = {
        0: {
          color: "rgba(169, 169, 169, 0.85)",
          background: "#EAEAEA",
        },
        1: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        2: {
          color: "#FFFFFF",
          background: "#D93747",
        },
        3: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        4: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        5: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        6: {
          color: "#FFFFFF",
          background: "#9AA9C3",
        },
        7: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        8: {
          color: "#FFFFFF",
          background: "#9AA9C3",
        },
      };
      return voteStatusCSS[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.getCheckIdentity();
  },
  methods: {
    toRouter(item) {
      if (item.name === "issueMeeting" && this.checkIdentity == 0) {
        this.$toast("您不是业委会、社区成员，暂无权限！");
        return;
      }
      this.$router.push({
        name: item.name,
      });
    },
    // 点击标题跳转议题详情页
    toDetail(item) {
      /* 权限 start */
      if (item.status == 0 && item.type != 3) {
        // 不是议题上会的待审核数据不可点击 - 实际上就是议事转换的业主大会在待审核状态不能点击
        return;
      }
      if (item.status == 0 && item.type == 3) {
        // 议题上会的待审核跳转支持、反对页面
        this.$router.push({
          name: "ownerDecision",
          query: {
            id: item.id,
          },
        });
        return;
      }
      /** end */
      this.$router.push({
        name: "voteDetail",
        query: {
          id: item.id,
        },
      });
    },

    // 获取用户信息
    async getCheckIdentity() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(checkIdentityURL, { params: params });
      if (res.code === 200) {
        this.checkIdentity = res.data;
      }
    },

    /* 接口 */
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(voteListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            type: 1, // 1 代表的是小程序端投票厅列表调用, 为2时为业主大会列表调用
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* 辅助方法 */
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.voteHall {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    padding: 36px 30px;
    .section-tabs {
      display: flex;
      justify-content: space-between;
      .section-tab {
        position: relative;
        width: 334px;
        max-width: 334px;
        height: 182px;
        &:last-child {
          .section-tab-txt {
            .section-tab-title {
              color: #7b4244 !important;
            }
          }
        }
        .section-tab-bg {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        }
        .section-tab-txt {
          position: absolute;
          top: 66px;
          left: 30px;
          .section-tab-title {
            line-height: 40px;
            font-size: 36px;
            font-weight: bold;
            color: #002244;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .section-tip {
      background: #ffffff;
      padding: 20px 24px;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 48px;
      font-size: 26px;
      margin-top: 30px;
      img {
        width: 32px;
        height: 40px;
        vertical-align: middle;
        overflow: hidden;
      }
    }
    .section-new-vote {
      margin-top: 30px;
      .vote-title {
        width: 100%;
        height: 92px;
        background: url("./img/new_vote_bg.png") no-repeat top left;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        span {
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            background: #ef6c4f;
            margin-right: 20px;
          }
          position: relative;
          display: flex;
          align-items: center;
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: bold;
        }
      }
      .vote-content {
        padding: 34px 24px 0;
        background: #ffffff;
        border-radius: 0 0 16px 16px;
        .vote-content-item {
          &:not(:first-child) {
            margin-top: 44px;
          }
          &:not(:last-child) {
            border-bottom: 2px solid #f3f3f3;
          }
          .content-item--text {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            line-height: 40px;
            &:extend(.dhsl);
            .tag {
              display: inline-block;
              padding-left: 38px;
              position: relative;
              img {
                position: absolute;
                top: 4px;
                left: 0px;
                width: 32px;
                height: 32px;
                vertical-align: middle;
                overflow: hidden;
              }
              span {
                color: #66aeff;
              }
            }
          }
          .content-item-date {
            font-size: 24px;
            color: #c8c8c8;
            line-height: 34px;
            height: 34px;
            margin: 14px 0;
          }
          .content-item-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 34px;
            .content-item-detail {
              flex: 1;
              font-size: 26px;
              color: #7b4244;
              line-height: 36px;
              &:extend(.dhsl-1);
            }
            .content-item-button {
              margin: 0 10px;
              border-radius: 8px;
              white-space: nowrap;
              font-size: 24px;
              line-height: 34px;
              padding: 6px 26px;
            }
          }
        }
      }
    }
  }
}
</style>
