<template>
  <div class="ownersMeeting">
    <div class="section-box">
      <div class="section-new-vote">
        <div class="vote-content">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="vote-content-item"
              v-for="(item, index) in listData"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="content-item--text">
                <!-- <div class="tag" v-show="item.type == 3">
                  <img src="./img/discussion_sh_icon.png" alt="" />
                  <span>议题上会：</span>
                </div> -->
                {{ item.title }}
              </div>
              <div class="content-item-date">
                <span>投票期：</span>
                <span>{{ `${item.voteStartDate}~${item.voteEndDate}` }}</span>
              </div>
              <div class="content-item-wrapper">
                <div class="content-item-detail">
                  <span>投票内容：</span>
                  <span>{{ item.votePublicityContent || item.content }}</span>
                </div>
                <div
                  class="content-item-button"
                  v-show="item.type != 3"
                  :style="item.status | btnCss"
                >
                  {{ item.status | btnTxt }}
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { cancelPraiseURL, addPraiseURL, voteListURL } from "./api";
import { voteStatusMap, setVoteStatusOps } from "./map";

export default {
  name: "ownersMeeting",
  data() {
    return {
      /* v-list 通用 */
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [
        // {
        //   id: "1",
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   voteStartDate: "2023-2-28 10:30:47",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   type: 2,
        //   status: 4,
        // },
        // {
        //   id: "2",
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   voteStartDate: "2023-2-28 10:30:47",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   type: 3,
        //   status: 2,
        // },
        // {
        //   id: "2",
        //   text: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   detail:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   voteStartDate: "2023-2-28 10:30:47",
        //   voteEndDate: "2023-03-23 18:00:00",
        //   status: 4,
        //   picture: "",
        //   name: "刘月",
        //   isCryptonym: true, // 是否匿名
        //   isLike: true,
        //   readNum: 109,
        //   likeNum: 41,
        //   isStep: true,
        //   stepNum: 1,
        //   topicNum: 42,
        // },
      ], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      /** end */
    };
  },
  filters: {
    btnTxt(status) {
      if (status == 1 || status == 4) {
        // 审核通过、待投票 都显示为公示中
        return "公示中";
      }
      return voteStatusMap[status];
    },
    btnCss(status) {
      let voteStatusCSS = {
        0: {
          color: "rgba(169, 169, 169, 0.85)",
          background: "#EAEAEA",
        },
        1: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        2: {
          color: "#FFFFFF",
          background: "#D93747",
        },
        3: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        4: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        5: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        6: {
          color: "#FFFFFF",
          background: "#9AA9C3",
        },
        7: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        8: {
          color: "#FFFFFF",
          background: "#9AA9C3",
        },
      };
      return voteStatusCSS[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    // toRouter(item) {
    //   this.$router.push({
    //     name: item.name,
    //   });
    // },
    // 点击标题跳转议题详情页
    toDetail(item) {
      /* 权限 start */
      if (item.status == 0 && item.type != 3) {
        // 不是议题上会的待审核数据不可点击 - 实际上就是议事转换的业主大会在待审核状态不能点击
        return;
      }
      if (item.status == 0 && item.type == 3) {
        // 议题上会的待审核跳转支持、反对页面
        this.$router.push({
          name: "ownerDecision",
          query: {
            id: item.id,
          },
        });
        return;
      }
      /** end */
      this.$router.push({
        name: "voteDetail",
        query: {
          id: item.id,
        },
      });
      // let params = {
      //   id: item.id,
      // };
      // if (wx.miniProgram) {
      //   wx.miniProgram.navigateTo({
      //     url: `/xubPages/xxx/index?params=${JSON.stringify(params)}`,
      //   });
      // }
    },

    /* 接口 */
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(voteListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            type: 2,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 点赞、点踩、或点击评论
    clickOperate(item, str) {
      let hasStr = ""; // 需要修改的判断字段
      let countStr = ""; // 需要修改的总数字段
      if (str === "topic") {
        // 评论
        this.$toast({
          message: "请进入详情页评论！",
          duration: 1500,
        });
        return;
      } else if (str === "step") {
        // 点踩
        hasStr = "isStep";
        countStr = "stepNum";
      } else if (str === "like") {
        // 点赞
        hasStr = "isLike";
        countStr = "likeNum";
      }
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: item.id,
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            if (item[hasStr]) {
              // 取消
              item[hasStr] = false;
              item[countStr] -= 1;
            } else {
              // 点击
              item[hasStr] = true;
              item[countStr] += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    /* 辅助方法 */
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ownersMeeting {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    padding: 36px 30px;
    .section-tabs {
      display: flex;
      justify-content: space-between;
      .section-tab {
        position: relative;
        width: 334px;
        max-width: 334px;
        height: 182px;
        &:last-child {
          .section-tab-txt {
            .section-tab-title {
              color: #7b4244 !important;
            }
          }
        }
        .section-tab-bg {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        }
        .section-tab-txt {
          position: absolute;
          top: 66px;
          left: 30px;
          .section-tab-title {
            line-height: 40px;
            font-size: 36px;
            font-weight: bold;
            color: #002244;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .section-tip {
      background: #ffffff;
      padding: 20px 24px;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 48px;
      font-size: 26px;
      margin-top: 30px;
      img {
        width: 32px;
        height: 40px;
        vertical-align: middle;
        overflow: hidden;
      }
    }
    .section-new-vote {
      .vote-content {
        .vote-content-item {
          padding: 38px 24px 0;
          border-radius: 0 0 16px 16px;
          background: #ffffff;
          &:not(:first-child) {
            margin-top: 18px;
          }
          .content-item--text {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            line-height: 40px;
            &:extend(.dhsl);
            .tag {
              display: inline-block;
              padding-left: 38px;
              position: relative;
              img {
                position: absolute;
                top: 4px;
                left: 0px;
                width: 32px;
                height: 32px;
                vertical-align: middle;
                overflow: hidden;
              }
              span {
                color: #66aeff;
              }
            }
          }
          .content-item-date {
            font-size: 24px;
            color: #c8c8c8;
            line-height: 34px;
            height: 34px;
            margin: 14px 0;
          }
          .content-item-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 34px;
            .content-item-detail {
              flex: 1;
              font-size: 26px;
              color: #7b4244;
              line-height: 36px;
              &:extend(.dhsl-1);
            }
            .content-item-button {
              margin: 0 10px;
              border-radius: 8px;
              white-space: nowrap;
              font-size: 24px;
              line-height: 34px;
              padding: 6px 26px;
            }
          }
        }
      }
    }
  }
}
</style>
