<template>
  <div class="vote-dialog" v-if="show">
    <van-overlay :show="show" :lock-scroll="false">
      <div class="section-wrapper">
        <div class="section-wrapper--flex">
          <!-- <div class="flex-closeBtn" v-show="showCloseBtn">
            <img src="../img/close_icon.png" alt="" />
          </div> -->
          <div class="flex-title">
            <span>{{ title }}</span>
          </div>
          <div class="flex-content">
            <v-h5Mtml :content="content" />
          </div>
          <div class="flex-check">
            <div class="flex-check--unprompt" @click="isRead = 0">
              <img
                src="../img/check_icon.png"
                v-if="isRead === 0 || isRead === '0'"
                alt=""
              />
              <div class="prefix-box" v-else></div>
              <span>已阅读，不再提示</span>
            </div>
            <div class="flex-check--prompt" @click="isRead = 1">
              <img src="../img/check_icon.png" v-if="isRead == 1" alt="" />
              <div class="prefix-box" v-else></div>
              <span>每次进入提示</span>
            </div>
          </div>
          <div class="flex-button">
            <div class="flex-button--check" @click="check">
              <span>确 定</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Overlay } from "vant";

export default {
  components: {
    vanOverlay: Overlay,
  },
  props: {
    // 显示弹窗控件
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    // 富文本框内容
    content: {
      type: String,
      default: "",
    },
    // // 显示关闭按钮
    // showCloseBtn: {
    //   type: Boolean,
    //   default: false,
    // },
    //
  },
  data() {
    return {
      isRead: undefined, // 是否已阅读
    };
  },
  computed: {},
  methods: {
    // 点击遮罩层
    // clickOverlay() {
    //   this.$emit("clickOverlay");
    // },
    // 确认按钮
    check() {
      if (this.isRead === undefined) {
        this.$toast("请选择是否提醒！");
        return;
      }
      this.$emit("check", this.isRead);
    },
  },
};
</script>
<style lang="less" scoped>
.vote-dialog {
  font-family: PingFangSC-Regular, PingFang SC;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9;
  .section-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .section-wrapper--flex {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100% - 84px);
      height: calc(100% - 128px);
      box-sizing: border-box;
      padding: 62px 30px 68px;
      background: #ffffff;
      border-radius: 16px;
      .flex-closeBtn {
        position: absolute;

        width: 40px;
        height: 40px;
        img {
          overflow: hidden;
          margin: 0;
          padding: 0;
          vertical-align: middle;
        }
      }
      .flex-title {
        text-align: center;
        color: #333333;
        font-size: 32px;
        line-height: 44px;
        font-weight: bold;
        padding-bottom: 38px;
      }
      .flex-content {
        box-sizing: border-box;
        flex: 1;
        // max-height: calc(100% - 100px);
        padding: 0 0 30px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .flex-check {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        height: 100px;
        min-height: 100px;
        .flex-check--unprompt,
        .flex-check--prompt {
          display: flex;
          align-items: center;
          img {
            width: 26px;
            min-width: 26px;
            height: 26px;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
          .prefix-box {
            box-sizing: border-box;
            width: 26px;
            height: 26px;
            border: 2px solid #dedede;
            border-radius: 4px;
          }
          span {
            margin: 0 48px 0 20px;
          }
        }
      }
      .flex-button {
        // margin: 30px auto 0;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .flex-button--check {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 30px;
          font-weight: bold;
          width: 80%;
          height: 74px;
          background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
          border-radius: 39px;
          border: 8px solid #ecf7ff;
        }
      }
    }
  }
}
</style>
