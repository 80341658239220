<template>
  <div class="vote-tip" v-if="show">
    <van-overlay :show="show">
      <div class="section-wrapper">
        <div class="section-wrapper--flex">
          <div class="flex-closeBtn" v-show="showCloseBtn" @click="close">
            <img src="../img/close_icon.png" alt="" />
          </div>
          <div class="flex-title">
            <span>{{ title }}</span>
          </div>
          <div class="flex-content">
            <span>
              {{ content }}
            </span>
          </div>
          <div class="flex-button">
            <div class="flex-button--check" @click="check">
              <span>{{ confirmTxt }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Overlay } from "vant";

export default {
  components: {
    vanOverlay: Overlay,
  },
  props: {
    // 显示弹窗控件
    show: {
      type: Boolean,
      default: false,
    },
    // 标题
    title: {
      type: String,
      default: "提示",
    },
    // 提示内容
    content: {
      type: String,
      default: "",
    },
    // 确认按钮
    confirmTxt: {
      type: String,
      default: "确 认",
    },
    // 显示关闭按钮
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 点击遮罩层
    clickOverlay() {
      this.$emit("clickOverlay");
    },
    // 确认按钮
    check() {
      this.$emit("check");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.vote-tip {
  font-family: PingFangSC-Regular, PingFang SC;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  .section-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .section-wrapper--flex {
      position: relative;
      width: 568px;
      background: #ffffff;
      border-radius: 16px;
      .flex-closeBtn {
        position: absolute;
        right: 10px;
        top: 22px;
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin: 0;
          padding: 0;
          vertical-align: middle;
        }
      }
      .flex-title {
        text-align: center;
        color: #333333;
        font-size: 32px;
        line-height: 44px;
        font-weight: bold;
        padding: 50px 0 0 0;
      }
      .flex-content {
        box-sizing: border-box;
        padding: 28px 70px 40px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 28px;
        text-align: center;
      }
      .flex-button {
        border-top: 2px solid #f3f3f3;
        // display: flex;
        // justify-content: center;
        .flex-button--check {
          display: flex;
          justify-content: center;
          align-items: center;
          // color: #ffffff;
          // font-size: 30px;
          // font-weight: bold;
          // width: 80%;
          width: 100%;
          height: 86px;
          color: #3680ff;
          font-size: 32px;
          // background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
          // border-radius: 39px;
          // border: 8px solid #ecf7ff;
        }
      }
    }
  }
}
</style>
